<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path class="a" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" />
    <path
      class="b"
      d="M15.131,6.323C13.4,4.781,10.092,1.806,10.092,0c0,1.806-3.3,4.781-5.039,6.323a2.629,2.629,0,0,0,0,4.065C6.191,11.406,8.589,11,9.092,10c.007,0-1,3.991-1,4h4s-.989-4.013-1-4c.5,1,2.9,1.406,4.039.387A2.629,2.629,0,0,0,15.131,6.323Z"
      transform="translate(1.908 4)"
    />
  </svg>
</template>

<script>
export default {
  name: "Wildcard",
};
</script>

<style lang="scss" scoped>
.a {
  fill: #ffb400;
}
.b {
  fill: #fff;
}
</style>